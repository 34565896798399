import gb from './en-gb'
import { CommonLang } from './types'

const lang: CommonLang = {
  ...gb,
  name: 'US',
  organisation: 'organization',
  Organisation: 'Organization',
  district: 'district',
  District: 'District',
  pupil: 'student',
  Pupil: 'Student',
  pupils: 'students',
  Pupils: 'Students',
  labelled: 'labeled',
  licences: 'licenses',
  Licences: 'Licenses',
  licence: 'license',
  Licence: 'License',
  scheme: 'curriculum',
  Postcode: 'Zip',
  postcode: 'zip',
  County: 'State',
  county: 'state',
  Maths: 'Math',
  SchoolDistricts: 'School Districts who want to subscribe for more than one school.',
  SchemeText: 'Curriculum',
  favourite: 'favorite',
  Favourite: 'Favorite',
  favourited: 'favorited',
  Favourited: 'Favorited',
  favourites: 'favorites',
  Favourites: 'Favorites',
  colour: 'color',
  Colour: 'Color',
  colours: 'colors',
  Colours: 'Colors',
  normalised: 'normalized',
  contactPhone: 'tel: +18882543098',
  contactNumber: '888-254-3098',
  contactEmail: 'support@edshed.com',
  currency: '$',
  spellingPersonalPrice: '62.50',
  spellingTeacherPrice: '140.00',
  spellingPremiumPrice: '5.00',
  spellingAppPrice: '2.99',
  pupilsPerSpellingClassroom: '28',
  natCurriculum: 'ELA spelling standards',
  natCurr2: 'ELA spelling',
  customText: 'Custom lists can be created in our app and in the Word Lists section.<br />Pre-made scannable QR Code custom vocabulary lists made for the US are coming soon!',
  customHide: 'columns is-multiline is-hidden',
  spellingCurriculumKey: 'spelling-shed-curriculum',
  spellingCurriculumId: '7',
  mathsCurriculumKey: 'maths-shed-teaching-slides',
  litshedCurriculumKey: 'literacy-shed-plus',
  phonicsCurriculumKey: 'phonics-shed-us',
  school_year: 'grade',
  _subject_English: 'Language Arts',
  Primary_School: 'Elementary',
  MathShed: 'MathShed',
  mathsPersonalPrice: '62.50',
  mathsTeacherPrice: '140.00',
  mathsPremiumPrice: '5.00',
  mathsAppPrice: '2.99',
  pupilsPerMathsClassroom: '28',
  spelt: 'spelled',
  personalised: 'personalized',
  personalise: 'personalize',
  practise: 'practice',
  Practise: 'Practice',
  recognise: 'recognize',
  Recognise: 'Recognize',
  categorise: 'categorize',
  Categorise: 'Categorize',
  nb: 'Note:',
  programme: 'program',
  Programme: 'Program',
  optimise: 'optimize',
  Optimise: 'Optimize',
  synchronise: 'synchronize',
  synchronised: 'synchronized',
  synchronising: 'synchronizing',
  authorisation: 'authorization',
  Authorisation: 'Authorization',
  Secondary: 'Middle School',
  secondary: 'middle school',
  GPC: 'PGC',
  'grapheme-phoneme': 'phoneme-grapheme',
  'Grapheme-phoneme': 'Phoneme-grapheme',
  'Grapheme-Phoneme': 'Phoneme-Grapheme',
  Randomise: 'Randomize',
  randomise: 'randomize',
  Maximise: 'Maximize',
  maximise: 'maximize'
}

export default lang
