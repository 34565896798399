import { PagedResults } from './common'

export interface EventLogInfo {
  id: number
  created: Date
  user_id: number
  type: EventType
  subject_id: number
  ip_address: string | null
  info: string | null
  username: string
  school_name: string
}

export const EventType = ['log_in', 'user_deleted', 'school_deleted', 'assignment_deleted', 'assignment_pupil_removed', 'password_changed', 'honeypots_spent', 'group_pupil_removed', 'group_teacher_removed', 'group_deleted', 'school_user_removed', 'league_deleted', 'list_deleted', 'subscription_created', 'subscription_cancelled', 'reward_record_deleted', 'pupil_updated', 'school_org_type_updated', 'subscription_attempted', 'subscription_payment_challenged', 'subscription_payment_failed', 'subscription_activated', 'subscription_deactivated', 'user_created', 'log_in_sso', 'mfa_email_enabled', 'mfa_authenticator_enabled', 'mfa_email_disabled', 'mfa_authenticator_disabled', 'email_change_requested', 'email_change_approved', 'email_change_denied'] as const
export type EventType = typeof EventType[number]

export interface GetEventsParams {
  readonly user_id?: number
  readonly created_after?: Date
  readonly created_before?: Date
  readonly types?: readonly EventType[]
  readonly subject_id?: number
  readonly ip_address?: string
  readonly school_id?: number
}

export interface GetEventsResponse {
  events: PagedResults<EventLogInfo>
}
