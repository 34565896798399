// import { SchoolOrgType } from 'models/types'
import { SubscriptionPlanAccountRegion, SubscriptionPlanAccountType, SubscriptionPlanCostType, SubscriptionPlanGroup, SubscriptionPlanId } from '../../subscriptionPackages'
import { Bit, Locale } from './common'
import { StripeInvoiceAccountRegion, Subscription, SubscriptionPlanInfo, SubscriptionProductType } from './subscriptions'
import { ISO8601Date, SchoolOrgType } from '.'
import { CountryCode } from './country'

export interface SalesEngagementReportFilters {
  groups?: readonly SubscriptionPlanGroup[]
  products?: readonly SubscriptionProductType[]
  regions?: readonly SubscriptionPlanAccountRegion[]
  account_types?: readonly SubscriptionPlanAccountType[]
  cost_types?: readonly SubscriptionPlanCostType[]
}

export interface EngagementReportSchoolStub {
  id: number
  school_name: string
  contact_email: string
}

export interface EngagementReportSchoolInfo {
  id: number
  school_name: string
  contact_name: string
  contact_email: string
  admin_count: number
  teacher_count: number
  pupil_count: number
  log_ins: number
  admin_events: number
  spelling_games_played: number
  number_games_played: number
  quiz_games_played: number
  phonics_games_played: number
  log_ins_per_user: number | null
  admin_events_per_admin: number | null
  spelling_games_per_pupil: number | null
  number_games_per_pupil: number | null
  quiz_games_per_pupil: number | null
  phonics_games_per_pupil: number | null
  subscriptions: readonly {
    creation_timestamp: ISO8601Date,
    expiry: ISO8601Date,
    base_plan: SubscriptionPlanInfo,
    add_on_plans: SubscriptionPlanInfo[]
  }[]
}

export type EngagementReportStatus = 'pending' | 'ready' | 'failed'

export interface EngagementReportSnapshotInfo {
  id: number
  created: ISO8601Date
  filters: SalesEngagementReportFilters
  user_id: number
  status: EngagementReportStatus
  start: ISO8601Date
  end: ISO8601Date
  percentage_ready: number
}

export interface HeadlineSalesRequest {
  locale?: Locale
  organisation_type?: SchoolOrgType
}

export interface HeadlineSubscriptionProductSplit {
  total: number
  spelling: number
  number: number
  phonics: number
  literacy: number
}

export interface HeadlineUserTypeSplit {
  total: number
  pupils: number
  teachers: number
  admins: number
}

export interface HeadlineSalesResponse {
  active_subscriptions: HeadlineSubscriptionProductSplit
  active_users: HeadlineUserTypeSplit
  words_spelled: number
  quizzes_played: number
  organisation_type: SchoolOrgType | null
  locale: Locale | null
  last_calculated: ISO8601Date | null
  is_calculating: boolean
}

export interface SalesByTypeGraphRequest {
  products?: SubscriptionProductType[]
  account_region: StripeInvoiceAccountRegion
  countries?: CountryCode[]
  organisation_types?: SchoolOrgType[]
  start?: Date
  end?: Date
  period: 'day' | 'week' | 'month' | 'year'
}

export const RetentionEventType = ['cancelled', 'retained', 'retained_alt'] as const
export type RetentionEventType = typeof RetentionEventType[number]

export interface GetRetentionEventsRequest {
  id?: number
  products?: SubscriptionProductType[]
  account_region?: StripeInvoiceAccountRegion
  countries?: CountryCode[]
  organisation_types?: SchoolOrgType[]
  event?: RetentionEventType
  start?: Date
  end?: Date
}

export interface GetRetentionEventsResponse {
  id: number
  date: ISO8601Date
  value: number
  organisation_type: SchoolOrgType | null
  account_region: StripeInvoiceAccountRegion
  subscription_id: number | null
  invoice_id: number | null
  country: CountryCode | null
  event: RetentionEventType
  school_id: number | null
  ignored: boolean
  // sub?: Subscription
}

export interface EditRetentionEventRequest {
  ignored?: boolean
}

export interface SalesByTypeGraphResponse {
  data: {
    period_start: ISO8601Date
    period_end: ISO8601Date
    new_sales: number
    new_sales_count: number
    retention_sales: number
    retention_sales_count: number
    upgrade_sales: number
    upgrade_sales_count: number
  }[],
  organisation_types: SchoolOrgType[]
  countries: CountryCode[]
  products: SubscriptionProductType[]
  account_region: StripeInvoiceAccountRegion
}

export interface RetentionGraphResponse {
  data: {
    period_start: ISO8601Date
    period_end: ISO8601Date
    subscriptions_due: number
    subscriptions_renewed: number
    value_due: number
    value_renewed: number
  }[],
  organisation_types: SchoolOrgType[]
  countries: CountryCode[]
  products: SubscriptionProductType[]
  account_region: StripeInvoiceAccountRegion
}

export const StripeBillingReason = ['subscription_create', 'subscription_cycle', 'subscription_update', 'manual'] as const
export type StripeBillingReason = typeof StripeBillingReason[number]

export interface StripeInvoiceEvent {
  data: {
    object: {
      billing_reason: StripeBillingReason
      lines: {
        data: {
          plan: {
            id: SubscriptionPlanId
          }
        }[]
      }
    }
  }
}

export const SalesActiveFilters = ['Active', 'Not Active'] as const
export type SalesActiveFilters = typeof SalesActiveFilters[number]

export const SalesSourcePersons = ['Ian Partridge', 'Martin Saunders', 'Rob Smith', 'Amelia Rimmer'] as const
export type SalesSourcePersons = typeof SalesSourcePersons[number]

export const SalesSourceDepts = ['Sales', 'Training', 'Marketing', 'Support'] as const
export type SalesSourceDepts = typeof SalesSourceDepts[number]
export interface SalesByPersonRequest {
  person?: string | null
  start?: Date | null
  end?: Date | null
  period?: 'day' | 'week' | 'month' | 'year'
}

export interface SalesByPersonResponse {
  data: {
    period_start: ISO8601Date
    period_end: ISO8601Date
    personsData: PersonData[]
  }[]
}

export interface PersonData {
  person: SalesPerson
  subscriptions: number
  merch: number
  total: number
}

export interface SalesByDeptRequest {
  dept?: string | null
  start?: Date | null
  end?: Date | null
  period?: 'day' | 'week' | 'month' | 'year'
}

export interface SalesByDeptResponse {
  data: {
    period_start: ISO8601Date
    period_end: ISO8601Date
    deptsData: DeptData[]
  }[]
}

export interface DeptData {
  dept: SalesSourceDepts
  subscriptions: number
  merch: number
  total: number
}

export interface SalesPerson {
  id: number
  name: string
  is_active: Bit
}

export interface SalesPersonFilters {
  active?: SalesActiveFilters
}
