import { Bit, ISO8601Date, Locale } from './common'
import { HiveWordData } from './hive'
import { GameplayWordToSave, UpdateCompetenceResponse } from './lists'
import { DictionaryInfo, UserModel } from '.'

export interface SpellingHistoryInfo {
  timestamp: ISO8601Date
  percentage_score: number
  results: {
    dictionary_id: number | null
    correct: Bit
    word: string
  }[]
}

export interface SpellingHistoryListInfo {
  times_played: number
  id: number
  title: string
  s_code: string
}
export interface NumberGame {
  id: number
  timestamp: ISO8601Date
  user_id: number
  key: string
  score: number
  correct_count: number
  total_count: number
  duration: number
  difficulty: number
  earnings: number
  type: NumberGameType
  percentage_score: number
  results: readonly NumberGameAnswer[]
}

export interface NumberGameAnswer {
  id: number
  number_game_id: number
  question: string
  answer_given: string
  correct: Bit
  time: number
}

export interface HiveGameInfo {
  id: number
  room: number | null
  creator_id: number
  created: ISO8601Date
  expiry: ISO8601Date
  status: string
  list_id: number
  list_ident: string
  phonics: number
  words: string
  words_ids: HiveWordData[]
  difficulty: number
  show_words: number
  shuffle: number
  auto_play: number
  hub_audio: number
  show_leagues: number
  locale: string
  list_title: string
  creator_username: string
  teacher: number
  wordsData?: GameSessionWordData[]
  assignmentId?: number
  assignmentTitle?: string
  assignmentIsTest?: boolean
}

export interface HiveGameData {
  id: number
  room: number
  creator_id: number
  created: ISO8601Date
  expiry: ISO8601Date
  status: string
  list_id: number
  list_ident: string
  phonics: number
  words: string
  difficulty: number
  show_words: number
  shuffle: number
  auto_play: number
  hub_audio: number
  show_leagues: number
  locale: string
  data: any[]
  league: any[]
}

export interface AdaptiveSpellingGameSessionWithoutWordData {
  id: number
  locale: Locale
  phonics: number
  gameType?: SpellingGameType
  total_words?: number
  earnedReward?: boolean
}

export interface AdaptiveSpellingGameSession extends AdaptiveSpellingGameSessionWithoutWordData {
  currentWordData: AdaptiveGameSessionWordData
}

export interface MiniDiagnosticGameSession extends AdaptiveSpellingGameSessionWithoutWordData {
  currentWordData: GameSessionWordData
}

export type MiniDiagnosticGameSessionOrFinished = { session: MiniDiagnosticGameSession } | { finished: true }

export interface GameSession {
  id: number
  list: string
  difficulty: number
  wordsData: GameSessionWordData[]
  words: string[]
  locale: Locale
  phonics: number
  gameType?: SpellingGameType
  hive?: number
  hiveId?: number
  hubAudio?: number
  showLeagues?: number
}

export interface TestModeGameSession {
  id: number
  list: string
  wordsData: GameSessionWordData[]
  words: string[]
  locale: Locale
  existingScore: number | null
  playedWordIndex: number | null
  resultsArray: boolean[] | null
  testComplete: boolean
  difficulty: number
  phonics: number
}

// read only - do not use in API
export interface SpellingGameSession extends GameSession {
  readonly existingScore?: number
  readonly playedWordIndex?: number
  readonly resultsArray?: boolean[]
  readonly testComplete?: boolean
  readonly isTest?: boolean
  readonly practiceForTestHomeworkId?: number
}

export type SpellingGameHiveSession = Partial<Pick<SpellingGameSession, 'id'>> & Omit<SpellingGameSession, 'id'>

export interface GameSessionWordData {
  ident: string,
  text: string,
  dictionary: DictionaryInfo
  difficulty?: number
  adaptiveLevel?: number
  diagnostic?: boolean
}

export interface AdaptiveGameSessionWordData extends GameSessionWordData {
  difficulty: number
  adaptiveLevel: number
  focusPoolStatus: AdaptiveSpellingWordFocusPoolStatus
}
export interface GameSessionUpdateParams {
  score: number
  words: GameplayWordToSave[]
  difficulty: number
  hiveId?: number
  isTest?: boolean
}

export interface LogGameplayResponse {
  status: 'Success',
  user?: UserModel
  competence: UpdateCompetenceResponse
  id: number
}

export const SpellingGameType = ['spelling', 'sentences', 'definitions', 'squirrel', 'adaptive spelling', 'adaptive spelling mini-diagnostic'] as const
export type SpellingGameType = typeof SpellingGameType[number]

export const NumberGameType = ['standard', 'squirrel'] as const
export type NumberGameType = typeof NumberGameType[number]

export interface NumberGameSession {
  score: number
  answers: NumberGameAnswerGiven[]
  key: string
  difficulty: number
  type?: NumberGameType
}

export interface NumberGameAnswerGiven {
  question: string
  answer: string
  correct: '1' | '0'
  time: number
}

export const AdaptiveSpellingRegion = ['en_GB', 'en_US', 'en_ZA'] as const
export type AdaptiveSpellingRegion = typeof AdaptiveSpellingRegion[number]

export type AdaptiveSpellingWordFocusPoolStatus = 'current' | 'future' | 'pending mastery' | 'mastered' | null

export interface AdaptiveSpellingUserWord {
  dictionaryId: number
  word: string
  currentLevel: number
  difficultyIndex?: number
}

export interface AdaptiveSpellingResultRequest {
  days: number | 'all'
  maxCount?: number
  onlyCorrect?: boolean
  onlyExtreme?: boolean
}

export interface AdaptiveSpellingResult {
  dictionaryId: number
  date: ISO8601Date
  word: string
  answer: string
  difficultyIndex: number | null
  playedLevel: number | null
  correct: boolean
}
export interface AdaptiveSpellingUserLevelHistory {
  date: ISO8601Date
  level: number
}

export interface AdaptiveSpellingUsersLevelDelta {
  current: AdaptiveSpellingUserLevelHistory
  previous: AdaptiveSpellingUserLevelHistory | null
}

export interface AdaptiveSpellingUserProgress {
  userId: number
  hasStartedMiniDiagnostic: boolean
  hasCompletedMiniDiagnostic: boolean
  hasPlayedAdaptiveSpelling: boolean
  currentStage: number | null
  rewardsPlayCount: number
  canEarnPlayCountToday: boolean
  hasRewardToRedeem: boolean
  roundsPlayed: number
  wordsPlayed: number
  wordsMastered: number
  bonusHoneypotsEarned: number
  levelDelta?: AdaptiveSpellingUsersLevelDelta
}

export interface SaveBeekeeperGameResponse {
  status: true
  user: UserModel
  competence: UpdateCompetenceResponse
}

interface CustomNumberFormatOptions extends Intl.NumberFormatOptions {
  numberingSystem?: string;
}

export const localeStringOptions: CustomNumberFormatOptions = {
  numberingSystem: 'latn'
}

export function transformNumberQuestionToLocaleString (question: string | undefined): string {
  if (!question) {
    return ''
  }
  if (question === '...') { return '...' }
  const questionArray = question.split(' ')
  const nonDigitsSigns = ['+', '-', '=', '?', '÷', '×', ' ']
  return questionArray
    .map((i) => {
      if (nonDigitsSigns.includes(i)) {
        return i
      } else {
        return enforceLatinNumberSystem(parseFloat(i.replace(',', '')))
      }
    })
    .join(' ')
}

export function enforceLatinNumberSystem (number: number): string {
  if (typeof number !== 'number') {
    return ''
  }
  return number.toLocaleString(undefined, localeStringOptions)
}
