import { VoiceLocale } from '../../i18n'
import { ArrayElement, ISO8601Date, Locale } from './common'
import { CountryCode } from './country'
import { SchoolGameSettings } from './user'
import { AddressInfo, OneRosterProvider, StripeInvoiceAccountRegion, SubscriptionPlanInfo } from '.'

export const SchoolOrgType = ['school', 'teacher', 'business', 'parent', 'district', 'parent_placeholder', 'learner'] as const
export type SchoolOrgType = ArrayElement<typeof SchoolOrgType>

export const RosterType = ['wonde', 'clever', 'gg4l', 'classlink', 'd6'] as const
export type RosterType = typeof RosterType[number]

export const RosterSubRequirement = ['full', 'trial', 'freemium'] as const
export type RosterSubRequirement = typeof RosterSubRequirement[number]

export interface RosterOption {
  type: RosterType
  subscription_requirement: RosterSubRequirement[] | null
  org_requirement: SchoolOrgType[]
}

export const CleverStatus = ['classroom', 'requested', 'approved', 'cancelled'] as const
export type CleverStatus = ArrayElement<typeof CleverStatus>

export const WondeStatus = ['requested', 'approved', 'cancelled'] as const
export type WondeStatus = ArrayElement<typeof WondeStatus>

export interface SchoolCreateRequest {
  contact_email: string
  contact_name: string
  contact_phone: string
  school_address1: string
  school_address2: string
  school_country: string
  school_country_code: CountryCode
  school_county: string
  school_name: string
  school_postcode: string
  school_town: string
  school_type: string
  orgType: SchoolOrgType
  billing_name: string
  billing_address1: string
  billing_address2: string
  billing_town: string
  billing_county: string
  billing_postcode: string
  vatNum: string
  district_id?: number
  tax_exempt?: boolean
  method?: 'district_add'
  dfe_school_id?: number | null
  locale?: Locale
}

export interface SchoolUpdateRequest {
  contact_email?: string
  contact_name?: string
  contact_phone?: string
  school_address1?: string
  school_address2?: string
  school_country?: string
  school_country_code?: CountryCode
  school_county?: string
  school_name?: string
  school_postcode?: string
  school_town?: string
  school_type?: string
  orgType?: '' | 'school' | 'business' | 'teacher' | 'parent'
  billing_name?: string
  billing_address1?: string
  billing_address2?: string
  billing_town?: string
  billing_county?: string
  billing_postcode?: string
  vatNum?: string
  district_id?: number,
  locale?: Locale,
  leagues?: number,
  mdr?: string | null,
  nces?: string | null,
  settings?: SchoolGameSettings
  tax_exempt?: boolean
  xero_customer?: string | null
  voice_locale?: VoiceLocale
}

export interface SuperUserSchoolSearchResult {
  addresses?: AddressInfo[]
  school_district: string | null
  district_children: { school_id: number; school_name: string; }[]
  plans: {
    base_plan: SubscriptionPlanInfo
    add_on_plans: SubscriptionPlanInfo[]
  }[]
  invoice_ids: number[]
  count_pupil: number
  count_teacher: number
  count_school_admin: number
  count_district_admin: number
  id: number
  contact_name: string
  contact_email: string
  contact_phone: string
  school_name: string
  school_address1: string
  school_address2: string
  school_town: string
  school_county: string
  school_postcode: string
  billing_name: string
  billing_address1: string
  billing_address2: string
  billing_town: string
  billing_county: string
  billing_postcode: string
  school_country: string
  school_country_code: CountryCode
  org_type: SchoolOrgType
  district_id: number | null
  school_type: string
  stripe_customer: string | null
  xero_contact: string | null
  created: ISO8601Date
  code: string | null
  locale: Locale
  vat_number: string | null
  league_length: number
  wonde_id: string | null
  wonde_status: WondeStatus | null
  clever_oid: string | null
  clever_status: CleverStatus | null
  clever_token: string | null
  d6_id: number | null
  last_sync: string | null
  mdr: string | null
  nces: string | null
  last_sync_timestamp: Date | null
  one_roster_id: string | null
  one_roster_tenant_id: number | null
  clever_portal_url: string | null
  clever_login_methods: string | null
  account_region: StripeInvoiceAccountRegion,
  one_roster_type: OneRosterProvider | null,
  settings: SchoolGameSettings | null,
  tax_exempt: boolean
  stale: boolean
  location_address_id: number | null
  billing_address_id: number | null
}
