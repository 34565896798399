import { DisplayObject } from '../../scripts/gameobjects'
import { PhonicsDisplayObject } from './quiz'
export { Locale } from '../../i18n/locale-map'

/* eslint-disable camelcase */

/** Easy create opaque types ie. types that are subset of their original types (ex: positive numbers, uppercased string) */
export declare type Opaque<K, T> = K & {
  __TYPE__: T
}

export declare type OpaqueWithPrecision<K, P extends number, T> = K & {
  __TYPE__: T
  __PRECISION: P
}

export type UUID = Opaque<string, 'UUID'>
export type NumericString = Opaque<string, 'NumericString'>

/** Example of encoding of: "2019-08-20T09:32:56.000Z" */
export type ISO8601Date = Opaque<string, 'ISO8601Date'>

export type Base64String = Opaque<string, 'Base64String'>

export type DecimalString<P extends number> = OpaqueWithPrecision<string, P, 'DecimalString'>
export type FinancialString = DecimalString<2>

// A decimal with finite precission stored as an int by multiplying by 10^P
export type InflatedNumber<P extends number> = OpaqueWithPrecision<number, P, 'InflatedNumber'>

export type InflatedNumberString<P extends number> = OpaqueWithPrecision<number, P, 'InflatedNumberString'>

export function Now () {
  return new Date().toISOString() as ISO8601Date
}

export function toNumericString (val: number): NumericString {
  return `${val}` as NumericString
}

export function fromNumericString (val: NumericString): number {
  const res = parseInt(val, 10)

  if (isNaN(res)) {
    throw new TypeError(`$${val} is not a valid numeric string`)
  }

  return res
}

export function toDecimalString<P extends number> (val: number, precision: P): DecimalString<P> {
  return val.toFixed(precision) as DecimalString<P>
}

export function toFinancialString (val: number) {
  return toDecimalString(val, 2) as FinancialString
}

export function toInflatedNumber<P extends number> (val: number, precision: P): InflatedNumber<P> {
  return val * 10 ** precision as unknown as InflatedNumber<P>
}

export function fromDecimalString<P extends number> (str: DecimalString<P>) {
  return parseFloat(str)
}

export function fromFinancialString (str: FinancialString) {
  return fromDecimalString(str)
}

export function fromInflatedNumber<P extends number> (num: InflatedNumber<P>, precision: number & P): number {
  return (num as unknown as number) / 10 ** precision
}

export function isAssignableTo<T extends string> (val: string, union: readonly T[]): val is T {
  return union.includes(val as T)
}

export type JsonString<TPayload> = string & { __payload: TPayload }

/** Type safe JSON string decoding */
export function fromJsonString<TPayload> (jsonString: JsonString<TPayload>, options: { default?: TPayload } = {}): TPayload {
  const payload: TPayload = jsonString ? JSON.parse(jsonString) : options.default

  return payload
}
/**
 * Inflates numeric strings by manipulating the decimal place to avoid floating point representation errors
 * @param val The uninflated input
 * @param precision The amount of spaces to move the decimal place by
 */
export function toInflatedNumberString<P extends number> (val: string, precision: P): InflatedNumberString<P> {
  const asNumber = parseFloat(val)

  if (isNaN(asNumber)) {
    throw new TypeError('Not valid input')
  }

  let placesToMove = Math.abs(precision)
  const unitDirection = precision > 0 ? 1 : precision < 0 ? -1 : 0
  let newVal = val.includes('.') ? val : val + '.'

  do {
    const decimalPos = newVal.indexOf('.')

    const numsBefore = newVal.slice(0, decimalPos).replace(/^0*/, '')
    const numsAfter = newVal.slice(decimalPos + 1).replace(/0*$/, '')

    let newNumsBefore = numsBefore
    let newNumsAfter = numsAfter

    if (unitDirection === 1) {
      newNumsBefore = numsBefore + (numsAfter.length > 0 ? numsAfter.slice(0, 1) : '0')
      newNumsAfter = numsAfter.length > 0 ? numsAfter.slice(1) : ''
    } else if (unitDirection === -1) {
      newNumsBefore = numsBefore.length > 0 ? numsBefore.slice(0, numsBefore.length - 1) : ''
      newNumsAfter = (numsBefore.length > 0 ? numsBefore.slice(numsBefore.length - 1, numsBefore.length) : '0') + numsAfter
    }

    newVal = newNumsBefore.length === 0 ? '0' : newNumsBefore

    if (placesToMove > 1 || newNumsAfter.length > 0) {
      newVal += '.' + newNumsAfter
    }

    placesToMove--
  } while (placesToMove > 0)

  return newVal as unknown as InflatedNumberString<P>
}

export function fromInflatedNumberString<P extends number> (num: InflatedNumber<P>, precision: number & P): string {
  return toInflatedNumberString(num as unknown as string, precision * -1) as unknown as string
}

export function toJsonString<TPayload extends object | null> (payload: TPayload): TPayload extends null ? null : JsonString<TPayload> {
  if (payload === null) {
    return null as any
  }

  return JSON.stringify(payload) as any
}

/**
 * Parses a unix timestamp in seconds or milliseconds, or a date object into an ISO8601 date string
 */
export function toISO8601Date (date: number | Date): ISO8601Date {
  if (typeof date === 'number') {
    const tryParseDate = new Date(date * 1000)

    if (tryParseDate.getFullYear() > 3000) {
      // we received value was already in ms
      const correctParseDate = new Date(date)
      return correctParseDate.toISOString() as ISO8601Date
    } else {
      return tryParseDate.toISOString() as ISO8601Date
    }
  } else if (date instanceof Date) {
    return date.toISOString() as ISO8601Date
  } else {
    throw new TypeError(`${date} could not be parsed as ISO8601Date`)
  }
}

export function dateFromISO8601Date (date: ISO8601Date): Date {
  return new Date(date)
}

export const Bit = [0, 1] as const
/** MySQL's representation of boolean */
export type Bit = ArrayElement<typeof Bit>

export const ResourceAvailability = ['free', 'subscription'] as const
export type ResourceAvailability = ArrayElement<typeof ResourceAvailability>

export const ResourceRequiredPermissions = ['number', 'literacy', 'spelling', 'phonics', 'phonics_lite_1', 'phonics_lite_2'] as const
export type ResourceRequiredPermissions = ArrayElement<typeof ResourceRequiredPermissions>

export const QuestionSetPermissions = [...ResourceRequiredPermissions, 'quiz'] as const
export type QuestionSetPermissions = ArrayElement<typeof QuestionSetPermissions>

export const SpellingGameTheme = ['spelling', 'spelling-football', 'spelling-van', 'spelling-horses'] as const
export type SpellingGameTheme = ArrayElement<typeof SpellingGameTheme>

export const GameTheme = ['quiz', 'number', 'phonics', 'grammar', ...SpellingGameTheme] as const
export type GameTheme = ArrayElement<typeof GameTheme>

export interface StoredFile {
  /** Computed */
  filePath?: string
  internalPath: string
  extname: string
}

export interface FileUpload {
  data: string
  extname: string
  size?: number
  name?: string
}

export function isStoredFile (data: StoredFile | FileUpload): data is StoredFile {
  return (data as any).internalPath !== undefined
}

export interface MultipartUploadData {
  hash: string
  file_type: string
  mime_type: string
  chunks: number
}

export interface MultipartUploadResponse {
  urls: string[]
  upload_id: string
}

export interface CloseMultipartUploadRequest {
  hash: string
  file_type: string
}

export interface CloseMultipartUploadResponse {
  file: StoredFile
}

export interface StoredImage {
  /** Stored in DB */
  fullSizeRelPath: string
  /** Stored in DB */
  thumbRelPath: string

  /** Computed */
  fullSizePath?: string
  /** Computed */
  thumbnailPath?: string
}

export interface ImageUpload {
  data: string
  extname: string
}

export interface FileAttachment {
  id?: number
  name: string
  description: string
  file?: StoredFile | null
  new_file?: FileUpload | null
  deleted?: boolean
  viewable?: boolean
  downloadable?: boolean
}

export type ArrayElement<TArray extends readonly unknown[]> = TArray extends readonly (infer TElement)[] ? TElement : never

export interface RotatedRect {
  /** The center x-coord of the rect */
  x: number;
  /** The center y-coord of the rect */
  y: number;
  /** Angle in radians */
  angle: number;
  width: number;
  height: number;
}
export interface Vertex {
  x: number
  y: number
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export class AccessDeniedError extends Error {
  public readonly status = 403

  constructor (message: string) {
    super(message)

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, AccessDeniedError.prototype)
  }
}

export class PasswordNeedsReverifiedError extends Error {
  public readonly status = 403

  constructor (message: string) {
    super(message)

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, PasswordNeedsReverifiedError.prototype)
  }
}

export class RateLimitError extends Error {
  public readonly status = 429
  public readonly timeToReset: number
  public readonly attemptsRemaining: number

  constructor (message: string, timeToReset: number, attemptsRemaining: number) {
    super(message)
    this.timeToReset = timeToReset
    this.attemptsRemaining = attemptsRemaining

    // Set the prototype explicitly. Known TS issue
    Object.setPrototypeOf(this, RateLimitError.prototype)
  }
}

export interface MixedContent {
  type: 'text' | 'html' | 'equation' | 'objects'
  value: string,
  objects?: (DisplayObject | PhonicsDisplayObject)[]
}

export interface IdRecord {
  id?: number | UUID
}

export type TableQuery = Paging & Sorting & Search

export type MaybePaged<E, T extends Paging | undefined> = T extends Paging ? PagedResults<E> : E[]

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
}

export interface Paging {
  skip?: number
  take?: number
}

export interface Sorting {
  sort?: string
  dir?: 'asc' | 'desc'
}

export interface Search {
  term?: string
}

export interface PagedResults<T> {
  items: T[]
  total: number
}

export interface TableState {
  page: number
  perPage: number
  sort: string
  dir: 'asc' | 'desc'
  term: string
}

export function getItemsFromMaybePagedResults<TResult, TPaging extends TableQuery | undefined> (maybePagedResults: MaybePaged<TResult, TPaging>, paging: TPaging): TResult[] {
  return paging ? (maybePagedResults as PagedResults<TResult>).items : (maybePagedResults as TResult[])
}

export interface Activity {
  date: ISO8601Date
  count: number
}

export interface QuizActivity {
  date: ISO8601Date
  spelling: number
  number: number
  quiz: number
  phonics: number
}

/** Lightweight user object */
export interface UserSummary {
  id: number
  username: string
  name: string
}

export interface IdOrIdent {
  id?: number
  ident?: any
}

export interface AppliedChildrenDelta<T extends number | string> {
  addedIds: T[]
  removedIds: T[]
  unchangedIds: T[]
}

export interface Scores {
  /** Sum of games played over last 180 hours (7.5 days) */
  score: number
  score_number: number
  score_quiz: number
  score_phonics: number
  score_grammar: number

  /** Sum of all games ever played (BIGINT) */
  total_score: string
  total_score_number: string
  total_score_quiz: string
  total_score_phonics: string
  total_score_grammar: string

  /** Highest shed score */
  high_score: number
  high_score_number: number
  high_score_quiz: number
  high_score_phonics: number
  high_score_grammar: number

  total_earnings: number

  total_rewards: number
}

export const BullJobStatus = ['completed', 'failed', 'delayed', 'active', 'waiting', 'paused', 'stuck'] as const
export type BullJobStatus = typeof BullJobStatus[number]

export const ClientType = ['edshed', 'edshed-hub', 'unity-spelling', 'unity-maths', 'unity-test', 'edshed-avatars'] as const
export type ClientType = typeof ClientType[number]

export function bitwiseToFlags<T extends string> (bitwise: number, flagNames: readonly T[]): Record<T, boolean> {
  const flags = {} as Record<T, boolean>

  flagNames.forEach((flag, index) => {
    flags[flag] = (bitwise & (1 << index)) !== 0
  })

  return flags
}

export function flagsToBitwise<F extends string, T extends Record<F, boolean>> (flags: T, flagNames: readonly F[]): number {
  let bitwise = 0

  flagNames.forEach((flag, index) => {
    if (flags[flag as keyof T]) {
      bitwise |= (1 << index) // Set the bit if the flag is true
    }
  })

  return bitwise
}
